const CONTENT_TYPE_APPLICATION_JSON = "application/json";
const STORAGE_ITEM_ACCESS_TOKEN = "accessToken";
const STORAGE_ITEM_TOKEN_TYPE = "tokenType";

const setTokenInStorage = (token) => {
    localStorage.setItem(STORAGE_ITEM_ACCESS_TOKEN, token.accessToken);
    localStorage.setItem(STORAGE_ITEM_TOKEN_TYPE, token.type);
};

export default class Api {
    constructor(baseUrl) {
        this.baseUrl = baseUrl;
    }

    async getClubMembers(clubId, signal){
        const res = await this.doCall(
            `/student-admin?${new URLSearchParams({ club_id: clubId })}`,
            "GET",
            null,
            signal
        );
        return res.json();
    }

    async addToAdmin(clubId, studentId, signal){
        const res = await this.doCall(
            "/student-club/to-club-admin",
            "POST",
            {
                club_id: clubId,
                admin_id: studentId,
            },
            signal
        );
        return res.json();
    }

    async removeFromAdmin(clubId, adminId, signal){
        const res = await this.doCall(
            "/student-club/to-club-member",
            "POST",
            {
                club_id: clubId,
                admin_id: adminId,
            },
            signal
        );
        return res.json();
    }

    async getEventDetails(eventId, signal) {
        const res = await this.doCall(
            `/event?${new URLSearchParams({ eventId: eventId })}`,
            "GET",
            null,
            signal
        );
        return res.json();
    }

    async getClubEvents(clubId, signal) {
        const res = await this.doCall(
            `/event?${new URLSearchParams({ club_id: clubId })}`,
            "GET",
            null,
            signal
        );
        return res.json();
    }

    async createEvent(eventData, signal) {
        const res = await this.doCall(
            "/student-club/event",
            "POST",
            eventData,
            signal
        );
        return res.json();
    }

    async updateEvent(clubId, eventId, eventData, signal) {
        const res = await this.doCall(
            `/student-club/event?${new URLSearchParams({ event_id: eventId })}`,
            "PUT",
            {
                club_id: clubId,
                venue_id: eventData.venue.venue_id,
                title: eventData.title,
                event_description: eventData.description,
                event_date: eventData.date,
                event_cost: eventData.cost,
                capacity: eventData.capacity,
            },
            signal
        );
        return res.json();
    }

    async deleteEvent(clubId, eventId, signal) {
        const res = await this.doCall(
            `/student-club/event?${new URLSearchParams({ event_id: eventId })}`,
            "DELETE",
            {club_id: clubId},
            signal
        );
        return res.json();
    }

    async fetchEvents(query="", signal) {
        const searchParams = query ? query : "";
        console.log(searchParams);
        const res = await this.doCall(
            `/event?${new URLSearchParams({ query: searchParams })}`,
            "GET",
            null,
            signal
        );
        return res.json();
    }

    async getAdminedClubs(signal) {
        const res = await this.doCall(
            `/student-club`,
            "GET",
            null,
            signal
        );
        return res.json();
    }

    async getClubDetails(clubId, signal) {
        const res = await this.doCall(
            `/student-club?${new URLSearchParams({ club_id: clubId })}`,
            "GET",
            null,
            signal
        );
        return res.json();
    }

    async getVenues(signal) {
        const res = await this.doCall("/venue", "GET", null, signal);
        return res.json();
    }

    async getFundingApplicationDetails(clubId, signal) {
        const res = await this.doCall(
            `/student-club/funding?${new URLSearchParams({ club_id: clubId })}`,
            "GET",
            null,
            signal
        );
        return res.json();
    }

    async createFundingApplication(fundingData, signal) {
        const res = await this.doCall(
            "/student-club/funding",
            "POST",
            {
                club_id: fundingData.club_id,
                application_funds: fundingData.application_funds,
                application_description: fundingData.application_description,
                submission_date: fundingData.submission_date,
                application_status: fundingData.application_status,
            },
            signal
        );
        return res.json();
    }

    async updateFundingApplication(clubId, fundingData, signal) {
        const res = await this.doCall(
            `/student-club/funding?${new URLSearchParams({ club_id: clubId })}`,
            "PUT",
            {
                club_id: fundingData.club_id,
                application_funds: fundingData.applicationFunds,
                application_description: fundingData.applicationDescription,
                submission_date: fundingData.applicationSubmissionDate,
                application_status: fundingData.applicationStatus,
            },
            signal
        );
        return res.json();
    }

    async login(username, password, signal) {
        const res = await this.doCall(
            "/auth/token",
            "POST",
            { username, password },
            signal
        );
        if (res.status > 299) {
            throw new Error(
                `expecting success from API for POST /auth/token but response was status ${res.status}: ${res.statusText}`
            );
        }
        const token = await res.json();
        setTokenInStorage(token);
        return token;
    }

    async logout(username, signal) {
        await this.doCall("/auth/logout", "POST", { username }, signal);
        localStorage.removeItem(STORAGE_ITEM_ACCESS_TOKEN);
        localStorage.removeItem(STORAGE_ITEM_TOKEN_TYPE);
    }

    async refreshToken(accessToken, signal) {
        const path = "/auth/token";
        const res = await fetch(`${this.baseUrl}${path}`, {
            method: "PUT",
            headers: {
                "Content-Type": CONTENT_TYPE_APPLICATION_JSON,
                Accept: CONTENT_TYPE_APPLICATION_JSON,
            },
            body: JSON.stringify({
                accessToken,
            }),
            signal,
            credentials: "include",
        });
        if (res.status > 299) {
            throw new Error(
                `expecting success from API for PUT ${path} but response was status ${res.status}: ${res.statusText}`
            );
        }
        const token = await res.json();
        setTokenInStorage(token);
        return token;
    }

    async getMemberRsvp(signal) {
        const res = await this.doCall(
            `/rsvp`,
            "GET",
            null,
            signal
        );
        return res.json();
    }

    async createReservation(reservationData, signal) {
        const res = await this.doCall(
            "/rsvp",
            "POST",
            reservationData,
            signal
        );
        const data = await res.json();

        if (data.error) {
            let invalidEmail;
            const errorMessage = data.error;

            // Extract invalid email from error message if it exists
            if (errorMessage.includes("Invalid email")) {
                const emailMatch = errorMessage.match(/Invalid email: ([^\s]+).*/);
                if (emailMatch && emailMatch[1]) {
                    invalidEmail = emailMatch[1];
                }
            }

            const userFriendlyMessage = invalidEmail
                ? `The email address "${invalidEmail}" is not a student address. You can only register RSVP with student email.`
                : 'An error occurred while creating the reservation.';

            console.error(userFriendlyMessage);
            throw new Error(userFriendlyMessage);
        }

        return data;
    }

    async deleteRSVP(rsvpId, signal) {
        const res = await this.doCall(
            `/rsvp?${new URLSearchParams({ rsvp_id: rsvpId })}`,
            "DELETE",
            null,
            signal
        );
        return res.json();
    }

    async deleteTicket(rsvpId, attendeeId, signal) {
        console.log("DeleteTicket");
        console.log(rsvpId);
        console.log(attendeeId);
        const res = await this.doCall(
            `/ticket?${new URLSearchParams({ rsvp_id: rsvpId, attendee_id: attendeeId })}`,
            "DELETE",
            null,
            signal
        );
        return res.json();
    }

    async doCall(path, method, data, signal) {
        const headers = {
            "Content-Type": CONTENT_TYPE_APPLICATION_JSON,
            Accept: CONTENT_TYPE_APPLICATION_JSON,
        };
        const accessToken = localStorage.getItem(STORAGE_ITEM_ACCESS_TOKEN);
        if (accessToken) {
            headers.Authorization = `${localStorage.getItem(
                STORAGE_ITEM_TOKEN_TYPE
            )} ${accessToken}`;
        }
        let body;
        if (data) {
            body = JSON.stringify(data);
        }
        const res = await fetch(`${this.baseUrl}${path}`, {
            method,
            headers,
            body,
            signal,
            credentials: "include",
        });
        console.log(path);
        if (res.status === 401 && accessToken) {
            await this.refreshToken(accessToken, signal);
            return this.doCall(path, method, data, signal);
        }
        if (res.status > 299) {
            throw new Error(
                `expecting success from API for ${method} ${path} but response was status ${res.status}: ${res.statusText}`
            );
        }
        return res;
    }
}
