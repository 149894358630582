import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useFundingDetails } from "../hooks/useFundingDetails";
import FundingApplicationDetails from "../components/FundingApplicationDetails";
import CreateFundingApplicationForm from "../components/createFundingApplication";
import UpdateFundingApplication from "../components/UpdateFundingApplication";

const StudentClubFunding = () => {
    const { club_id } = useParams();
    const clubId = parseInt(club_id);
    const { fundingDetails, loading, error } = useFundingDetails(clubId);
    const [isEditing, setIsEditing] = useState(false);

    console.log('fundingDetails:', fundingDetails);

    if (loading) {
        return <div className="text-center text-xl mt-10">Loading...</div>;
    }
    if (error) {
        return <div className="text-center text-red-500 mt-10">{error}</div>;
    }

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleUpdate = () => {
        setIsEditing(false);
    };

    if (fundingDetails.club_id === clubId) {
        if (isEditing) {
            return (
                <>
                    <div className="p-4">
                        <div className="bg-white rounded-lg p-6 mb-6 shadow-lg">
                            <div className="flex justify-between mb-4">
                                <h1 className="text-2xl font-bold">Funding Application for this semester</h1>
                            </div>
                            <UpdateFundingApplication funding={fundingDetails} onUpdate={handleUpdate} />
                        </div>
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <div className="p-4">
                        <div className="bg-white rounded-lg p-6 mb-6 shadow-lg">
                            <div className="flex justify-between mb-4">
                                <h1 className="text-2xl font-bold">Funding Application for this semester</h1>
                                <button className="text-blue-500" onClick={handleEdit}>Edit</button>
                            </div>
                            <FundingApplicationDetails fundingDetails={fundingDetails} />
                        </div>
                    </div>
                </>
            );
        }
    } else {
        return (
            <>
                <CreateFundingApplicationForm clubId={clubId} />
            </>
        );
    }
};

export default StudentClubFunding;