import React, { useState } from "react";
import PropTypes from "prop-types";
import { useCreateFunding } from "../hooks/useCreateFunding";

const CreateFundingApplication = ({ clubId }) => {
    const { loading, error, createFunding } = useCreateFunding();
    const [fundingData, setFundingData] = useState({
        submission_date: "",
        application_status: "",
        application_description: "",
        application_funds: 0,
        club_id: clubId,
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFundingData({
            ...fundingData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        createFunding({ ...fundingData, clubId });
        window.location.reload();
    };

    return (
        <div className="p-4">
            <div className="bg-white rounded-lg p-6 mb-6 shadow-lg">
                <div className="flex justify-between mb-4">
                    <h1 className="text-2xl font-bold">Create Funding Application</h1>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label
                            htmlFor="submission_date"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Application Date
                        </label>
                        <input
                            type="date"
                            name="submission_date"
                            id="submission_date"
                            value={fundingData.submission_date}
                            onChange={handleInputChange}
                            className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                        />
                    </div>
                    <div className="mb-4">
                        <label
                            htmlFor="application_status"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Application Status
                        </label>
                        <select
                            name="application_status"
                            id="application_status"
                            value={fundingData.application_status}
                            onChange={handleInputChange}
                            className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                        >
                            <option value="draft">In Draft</option>
                            <option value="submitted">Submitted</option>
                            <option value="review">In Review</option>
                            {/* <option value="approved">Approved</option>
                            <option value="rejected">Rejected</option> */}
                            <option disabled={fundingData.application_status.toLowerCase() === "approved" || fundingData.application_status.toLowerCase() === "rejected"} value="cancel">Cancel</option>
                        </select>
                    </div>
                    <div className="mb-4">
                        <label
                            htmlFor="application_description"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Application Description
                        </label>
                        <input
                            type="text"
                            name="application_description"
                            id="application_description"
                            value={fundingData.application_description}
                            onChange={handleInputChange}
                            className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                        />
                    </div>
                    <div className="mb-4">
                        <label
                            htmlFor="application_funds"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Application Funds
                        </label>
                        <input
                            type="number"
                            name="application_funds"
                            id="application_funds"
                            value={fundingData.application_funds}
                            onChange={handleInputChange}
                            className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                        />
                    </div>
                    <button
                        type="submit"
                        className="bg-green-500 px-4 py-2 rounded-lg text-white"
                        onClick={handleSubmit}
                    >
                        Create Funding Application
                    </button>
                </form>
            </div>
        </div>
    );
};

CreateFundingApplication.propTypes = {
    clubId: PropTypes.number.isRequired,
};

export default CreateFundingApplication;