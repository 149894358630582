import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link, Navigate } from 'react-router-dom';
import ApiProvider from './services/ApiProvider';
import AuthenticationProvider from './services/AuthenticationProvider';
import LoggedInUserDetail from './components/LoggedInUserDetail';
import StudentAdminDashboard from "./pages/StudentAdminDashboard"
import StudentClubDashboard from './pages/StudentClubDashboard';
import MyRSVPPage from './pages/student/RSVP';
import Dashboard from './pages/student/StudentMemberDashboard'
import Login from './pages/Login';
import Home from './pages/Home';

import WithAuthority from './hoc/WithAuthority';
import StudentClubFunding from './pages/StudentClubFunding';

function App() {

  return (
    <ApiProvider>
      <AuthenticationProvider>
      <LoggedInUserDetail />
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/admin/login" element={<Login />} />
          <Route path="/student-admin-dashboard/" element={(
            <WithAuthority authorities={['ROLE_STUDENT_ADMIN']}>
              <StudentAdminDashboard />
            </WithAuthority>
          )} />
          <Route path="/student-club-dashboard/:club_id" element={(
            <WithAuthority authorities={['ROLE_STUDENT_ADMIN']}>
              <StudentClubDashboard />
            </WithAuthority>
          )} />
          <Route path="/student-club-dashboard/funding/:club_id" element={(
            <WithAuthority authorities={['ROLE_STUDENT_ADMIN']}>
              <StudentClubFunding />
            </WithAuthority>
            )} />
          <Route path="/student-member-dashboard" element={(
            <WithAuthority authorities={['ROLE_STUDENT']}>
              <Dashboard />
            </WithAuthority>
            )}/>
            <Route path="/student-member-dashboard/rsvp" element={(
               <WithAuthority authorities={['ROLE_STUDENT']}>
                <MyRSVPPage />
              </WithAuthority>
            )}/>
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </AuthenticationProvider>
    </ApiProvider>
  );
}

export default App;
