import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthentication } from '../services/AuthenticationProvider';
import Error from '../components/Error';
import Button from '../components/Button';
import Input from '../components/Input';
import Page from '../components/Page';

export default function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const { login, authenticationError, authenticating } = useAuthentication();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const isAdmin = location.pathname === '/admin/login';

  const handleUsernameChanged = (event) => {
    event.preventDefault();
    setUsername(event.target.value);
  };

  const handlePasswordChanged = (event) => {
    event.preventDefault();
    setPassword(event.target.value);
  };

  const inputValid = () => username && password;

  const handleLogin = async () => {
    await login(username, password);
    if (authenticationError) {
      return;
    }
    if (isAdmin) {
      navigate('/student-admin-dashboard/');
      return;
    } else {
      navigate('/student-member-dashboard');
    }
    // let next = '/event';
    // if (location.state && location.state.next) {
    //   next = location.state.next;
    // }
    // navigate(next);
  };

  

  console.log('auth error', authenticationError);

  return (
<div className="flex min-h-screen items-center justify-center bg-gray-100">
  <div className="w-full max-w-md p-8 space-y-6 bg-white rounded-lg shadow-lg">
    {/* Title Based on Admin or Student */}
    <div className="flex justify-center">
      <h2 className="text-3xl font-bold text-gray-800">
        {isAdmin ? "Admin Login" : "Student Login"}
      </h2>
    </div>

    {/* Error Handling */}
    {authenticationError && <Error messages={['Incorrect Username or Password.', 'Please try again :/ ']} />}

    {/* Username Input */}
    <div>
      <label className="block text-sm font-medium text-gray-700" htmlFor="username">
        Username
      </label>
      <input
        id="username"
        type="text"
        placeholder="Pepe Roni"
        value={username}
        onChange={handleUsernameChanged} 
        className={`mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 ${authenticationError ? 'border-red-500' : 'border-gray-300'}`}
        required
      />
    </div>

    {/* Password Input */}
    <div>
      <label className="block text-sm font-medium text-gray-700" htmlFor="password">
        Password
      </label>
      <input
        id="password"
        type="password"
        placeholder="shhh"
        value={password}
        onChange={handlePasswordChanged} 
        className={`mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 ${authenticationError ? 'border-red-500' : 'border-gray-300'}`}
        required
      />
    </div>

    {/* Login Button */}
    <div>
      <button
        disabled={!inputValid() || authenticating} 
        onClick={handleLogin}
        className={`w-full px-4 py-2 font-medium text-white bg-blue-600 rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${!inputValid() || authenticating ? 'opacity-50 cursor-not-allowed' : ''}`}
      >
        {authenticating ? 'Logging in...' : 'Login'}
      </button>
    </div>
  </div>
</div>

  );
}