import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useReservation } from '../../hooks/useReservation';
import Notification from './Notification';

const EventDetailModal = ({ isOpen, onClose, event}) => {
  const [isReservationModalOpen, setIsReservationModalOpen] = useState(false);

  if (!isOpen || !event) return null;

  const handleReservationClick = () => {
    setIsReservationModalOpen(true);
  };

  const closeReservationModal = () => {
    setIsReservationModalOpen(false);
  };

  return (
    <>
      {/* Event Detail Modal */}
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white rounded-lg p-6 shadow-lg w-1/2">
          <h2 className="text-2xl font-semibold mb-4">{event.title}</h2>
          <p className="text-gray-700 mb-4">{event.description}</p>
          <p className="text-gray-500">Date: {event.date}</p>
          <p className="text-gray-500">Location: {event.venue.venue_name}</p>
          <p className="text-gray-500">Address: {event.venue.venue_address}</p>
          <p className="text-gray-500">Number of attendees: {event.numOfAttendees}</p>
          <button
            className="mt-4 px-4 py-2 bg-red-500 text-white rounded-lg"
            onClick={onClose}
          >
            Close
          </button>
          <button
            className="mt-4 ml-4 px-4 py-2 bg-blue-500 text-white rounded-lg"
            onClick={handleReservationClick}
          >
            Create Reservation
          </button>
        </div>
      </div>

      {/* Reservation Modal */}
      {isReservationModalOpen && (
        <ReservationModal isOpen={isReservationModalOpen} onClose={closeReservationModal} event={event} />
      )}
    </>
  );
};

const ReservationModal = ({ isOpen, onClose, event }) => {
    const { makeReservation, loading } = useReservation();
    const [email1, setEmail1] = useState('');
    const [email2, setEmail2] = useState('');
    const [email3, setEmail3] = useState('');
    const [notification, setNotification] = useState('');

    if (!isOpen) return null;

    const handleSubmit = async (e) => {
        e.preventDefault();
        const reservationData = {
            event_id: event.id,
            email_1: email1,
            email_2: email2,
            email_3: email3
        };

        try {
            await makeReservation(reservationData);
            setNotification({
                message: 'Reservation created successfully!',
                type: 'success'
            });
            setEmail1('');
            setEmail2('');
            setEmail3('');
        } catch (error) {
            let errorMessage = 'An unexpected error occurred.';
            if (error.response && error.response.data && error.response.data.error) {
                errorMessage = `Error: ${error.response.data.error}`;
            } else if (error.message) {
                errorMessage = `Error: ${error.message}`;
            }
            setNotification({
                message: errorMessage,
                type: 'error'
            });
        }
    };

    const handleCloseNotification = () => {
        setNotification('');
    };

    return (
        <>
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                <div className="bg-white rounded-lg p-6 shadow-lg w-1/2">
                    <h2 className="text-2xl font-semibold mb-4">Create Reservation</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <label className="block text-gray-700">First Attendee</label>
                            <input
                                type="email"
                                value={email1}
                                onChange={(e) => setEmail1(e.target.value)}
                                className="w-full px-3 py-2 border rounded-lg"
                                placeholder="Enter attendee's student email"
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700">Second Attendee</label>
                            <input
                                type="email"
                                value={email2}
                                onChange={(e) => setEmail2(e.target.value)}
                                className="w-full px-3 py-2 border rounded-lg"
                                placeholder="Enter attendee's student email"
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700">Third Attendee</label>
                            <input
                                type="email"
                                value={email3}
                                onChange={(e) => setEmail3(e.target.value)}
                                className="w-full px-3 py-2 border rounded-lg"
                                placeholder="Enter attendee's student email"
                                required
                            />
                        </div>
                        <button type="submit" className="mt-4 px-4 py-2 bg-green-500 text-white rounded-lg">
                            {loading ? 'Submitting...' : 'Submit Reservation'}
                        </button>
                        <button
                            type="button"
                            className="mt-4 ml-4 px-4 py-2 bg-red-500 text-white rounded-lg"
                            onClick={onClose}
                        >
                            Cancel
                        </button>
                    </form>
                    {notification && (
                        <Notification
                            message={notification.message}
                            type={notification.type}
                            onClose={handleCloseNotification}
                        />
                    )}
                </div>
            </div>
        </>
    );
};
  

ReservationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

EventDetailModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  event: PropTypes.object,
};

export default EventDetailModal;
